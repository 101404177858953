<template>
  <div class="titleLeftTop">
    <div class="tip yunxuancai" @click="routerIndbsc">
      <span>材料云</span>
    </div>
    <div class="tip lsmc" @click="routerInlsmc">
      <span>制造云</span>
    </div>
    <div class="tip dbsc" @click="routerInLsdc">
      <span>交付云</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    routerIndbsc() {
      this.$router.push('/dbsc')
    },
    routerInlsmc(){
      this.$router.push('/lsmc')
    },
    routerInLsdc() {
      this.$router.push('/lsdc')
    },
  }
}
</script>

<style lang="less" scoped>
.titleLeftTop{
  top: 0;
  left: 0;
  position: absolute;
  font-size: 0.18rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  .tip {
    width: 1.4rem;
    height: 0.44rem;
  }
  .dbsc {
    margin-left: -0.36rem;
    z-index: 1;
    line-height: 0.44rem;
    background: url("../assets/have.png") no-repeat;
    cursor: pointer;
    background-size: 100% 100%;
    span{
      //margin-right: 50px;
      text-align: right;
    }
  }
  .yunxuancai{
    // margin-left: -0.35rem;
    line-height: 0.44rem;
    background: url("../assets/have.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    z-index: 3;
    span{
      margin-right: 0.3rem;
    }
  }
  .lsmc{
    margin-left: -0.36rem;
    z-index:2;
    line-height: 0.44rem;
    background: url("../assets/have.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    span{
      text-align: right;
    }
  }
}
@media screen and (min-width: 2150px) {
  .titleLeftTop {
    font-size: .26rem;

    .tip {
      width: 1.85rem;
      height: 0.55rem;
      line-height: 0.55rem;
    }
  }
}
</style>
